import React from "react";
import styled from "styled-components";
import { Header } from "../../components/atoms";
import WhoWeAre from "./WhoWeAre"
import Team from "./Team"
import Mission from "./Mission"

export const About = () => (
    <Wrapper>
      <Header>
            About Us
      </Header>
      <WhoWeAre />
      <Team />
      <Mission />
    </Wrapper>
)

export default About;

const Wrapper = styled.div`
    text-align: center;
    margin-top: 20px;
`;