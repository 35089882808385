import React, { FC, memo, useContext } from 'react';
import { Link as _Link } from "react-router-dom";
import styled, { ThemeContext, css } from "styled-components";
import { fonts } from "../../styles";


interface Props {
    className?: string;
}

const Footer: FC<Props> = ({ className }) => {
    const theme = useContext(ThemeContext);
    return(
        <Wrapper className={className}>

            <Left>
                <Logo src={theme.img.logo} />
            </Left>

            <Right>
                <Copy>&copy; 2020 Facecheck Limited. All rights reserved.</Copy>
                <Link to="/privacy">Privacy</Link>
                <Link to="/privacy">Terms of use</Link>
            </Right>

        </Wrapper>
    )
}

export default memo(Footer);

const Wrapper = styled.div`
    width: 100%;
    background-color: white;
    border-top: 3px solid ${props => props.theme.color.silver};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 30px 40px;
    box-sizing: border-box;
`;

const Logo = styled.img`
    width: 104px;
    height: 80px;
`;

const Left = styled.div`
    text-align: left;
`;

const Right = styled.div`
    text-align: right;
    height: 100%;
    display: flex;
    align-items: center;
    @media only screen and (max-width: 850px) {
        flex-direction: column;
        align-items: flex-end;
    }
`;

const linkCss = css`
    font-size: 18px;
    font-family: ${fonts.gotham};
    text-decoration: none;
    color: ${props => props.theme.color.lightGray};
    margin-left: 16px;
    padding: 8px 22px;
    @media only screen and (max-width: 850px) {
        font-size: 16px;
        text-align: right;
    }
`;

const Copy = styled.span`
    ${linkCss}
`;

const Link = styled(_Link)`
    ${linkCss}
    &:hover {
        color: ${props => props.theme.color.primaryDarker1};
    }
`;
