import React, { useContext } from "react";
import styled, {ThemeContext} from "styled-components";
import { Header as _Header, Paragraph as _Paragraph } from "../../components/atoms";

const loremIpsum = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."

const WhoWeAre = () => {

    const theme = useContext(ThemeContext)

    return <Wrapper>
        
        <TextContainer>
            <Header size={30}>Who We Are</Header>
            <Paragraph>{loremIpsum} {loremIpsum}</Paragraph>
        </TextContainer>
        <Spacer/>
        <ImageContainer>
            <Image src={theme.img.about1}/>
        </ImageContainer>
        <VerticalSpacer/>
        <LastImageContainer>
            <Image src={theme.img.about2}/>
        </LastImageContainer>
        <TextContainer>
            <Header size={30}>Our Background</Header>
            <Paragraph>{loremIpsum} {loremIpsum}</Paragraph>
        </TextContainer>

    </Wrapper>
}

export default WhoWeAre

const Wrapper = styled.div`
    padding: 100px;
    margin: 0px auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    max-width: 1100px;

    @media only screen and (max-width: 1250px) {
        flex-direction: column;
        flex-wrap: nowrap;
        padding: 20px;
    }

    @media only screen and (max-width: 750px) {
        max-width: 100vw;
    }
`

const Header = styled(_Header)`
    text-align: left;
    margin-bottom: 20px;
`

const Paragraph = styled(_Paragraph)`
    max-width: 500px;
    text-align: left;
`

const TextContainer = styled.div`
    margin: auto 0px;
`

const Spacer = styled.div`
    width: 40px;
`

const VerticalSpacer = styled.div`
    width: 100vw;
    height: 120px;

    @media only screen and (max-width: 1250px) {
        height: 0px;
    }
`

const ImageContainer = styled.div`
    
    text-align: left;
    max-width: 500px;
    max-height: 500px;
    width: 100%;
    
    @media only screen and (max-width: 1250px) {
        margin-top: 40px;
        margin-bottom: 120px;
    }
`

const LastImageContainer = styled(ImageContainer)`
    @media only screen and (max-width: 1250px) {
        order: 100
    }
`

const Image = styled.img`
    
    max-width: 100%;
    max-height: inherit;
    border-radius: 10px;
    box-shadow: 50px 50px ${props => props.theme.color.primaryTransparent};
    object-fit: contain;
`