import React, {FC, useContext} from "react"
import styled, {ThemeContext} from "styled-components"
import { Header as _Header, Paragraph as _Paragraph, Button as _Button, BulletList as _BulletList } from "../../components/atoms"
import { Content, Brand } from "../../styles/theme"


const Pricing = () => {

    const brand: Brand = useContext(ThemeContext).brand;

    return <Wrapper>
        
        <MainHeader>Pricing</MainHeader>

        {pricingCategories.map(pc => <Card {...pc}/>)}

        <Paragraph>For more information on any of our plans, please get in touch</Paragraph>
        <DemoButton href={content[brand].demoUrl}>Book a demo</DemoButton>
    </Wrapper>
}

const content: Content = {
    work: {
        demoUrl: 'https://facecheckcontact.typeform.com/to/QO7QgV0x'
    },
    sport: {
        demoUrl: 'https://facecheckcontact.typeform.com/to/w2eMr6kx'
    },
    travel: {
        demoUrl: 'https://facecheckcontact.typeform.com/to/V3HYsPtP'
    }
}

export default Pricing

const pricingCategories = [
    {
        name: "SME",
        price: 8,
        summaryItems: [
            "1-250 users",
            "£500 upfront cost",
            "Monthly rolling contract"
        ]
    },
    {
        name: "Large",
        price: 4,
        summaryItems: [
            "251-5000 users",
            "£500 upfront cost",
            "6 month minimum, billed monthly",
            "Optional 1 month free trial"
        ]
    },
    {
        name: "Enterprise",
        price: 2,
        summaryItems: [
            "5000+ users",
            "£0 upfront cost",
            "6 month minimum, billed monthly",
            "Optional 1 month free trial"
        ]
    }
]

const Wrapper = styled.div`

    margin: 20px 0px;
    text-align: center;
`

const MainHeader = styled(_Header)`
    margin: 0px auto 20px auto;
`

const Header = styled(_Header)`
    margin: 0px auto;
`

type PricingProps = typeof pricingCategories[0]

const Card: FC<PricingProps> = ({ name, price, summaryItems }) => {

    const brand: Brand = useContext(ThemeContext).brand;
    
    return <CardWrapper>
        <Category>
            <Header size={18}>{name}</Header>
            <Header size={30}>£{price} per user</Header>
            <PerMonth/>
            <Button href={content[brand].demoUrl}>Get started</Button>
        </Category>
        <ListWrapper>
            <BulletList size={14}> {summaryItems.map(si => <li>{si}</li>)} </BulletList>
        </ListWrapper>
    </CardWrapper>
}

const CardWrapper = styled.div`
    margin: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    width: 300px;
    height: 350px;
    background-color: white;
    display: inline-block;
`

const Category = styled.div`
    background-color: ${props => props.theme.color.lighterGray};
    padding: 20px 0px 30px 0px;
`

const PerMonth = styled(_Paragraph)`

    font-size: 14px;
    line-height: 1em;
    margin: 0px;

    &:after{
        content: "/month";
    }
`

const Button = styled(_Button)`
    margin: 30px 30px 0px 30px;
    font-size: 18px;
    padding: 15px 20px;
    display: block;
`

const DemoButton = styled(Button)`
    margin: 20px auto 50px auto;
    // padding: 20px 0px;
    width: 250px;
    
`

const ListWrapper = styled.div`
    text-align: left;
`

const BulletList = styled(_BulletList)`

    margin: 1em 20px;
    padding: 0px;
    line-height: 2em;
    vertical-align: text-top;
    display: inline-block;
`

const Paragraph = styled(_Paragraph)`

    margin: 50px auto 0px auto;
    max-width: 1000px;
`