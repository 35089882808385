import React, { FC, memo, useContext } from 'react';
import styled, { ThemeContext } from "styled-components";
import { fonts } from "../../styles";
import {
    Header as _Header,
    Paragraph as _Paragraph,
    Button,
} from "../../components/atoms";
import { Content, Brand } from "../../styles/theme"

interface Props {
    onPlayClick: VoidFunction;
}

const Dashboard: FC<Props> = ({ onPlayClick }) => {
    const theme = useContext(ThemeContext);
    const brand: Brand = useContext(ThemeContext).brand;

    return (
        <Wrapper>
            <Top>
                <div>
                    <Image src={theme.img.dashboardOutline} alt="Dashboard preview" />
                </div>
                <Spacer />
                <div>
                    <Header size={48}>
                        Check. Manage. Protect.
                    </Header>
                    <Paragraph>
                        {content[brand].para}
                    </Paragraph>
                    <Link onClick={onPlayClick}>
                        See it in action
                    </Link>
                </div>
            </Top>
            <Bottom>
                <Button href="https://london-techworks.typeform.com/to/PeTFwA0o">
                    Book a demo
                </Button>
            </Bottom>
        </Wrapper>
    )
}

export default memo(Dashboard);

const content: Content = {
    work: {
        para: "Start a health, safety and wellbeing conversation with every worker and learn more about them and your organisation. The Fit for Work dashboard provides you and your organisation with the insights you need to keep your colleagues safe.",
    },
    sport: {
        para: "Start a health, safety and wellbeing conversation within your sports community. The Fit for Work dashboard provides you and your organisation with the insights you need to keep your members safe.",
    },
    travel: {
        para: "Start a health, safety and wellbeing conversation with your passengers. The Fit for Travel dashboard provides you and your organisation with the insights you need to keep your passengers safe.",
    }
}

const Wrapper = styled.div`
    padding: 0px 40px;
`;

const Top = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 90px;
    padding-bottom: 90px;
    @media only screen and (max-width: 750px) {
        flex-direction: column;
    }
`;


const Bottom = styled.div`
    margin-top: 20px;
    margin-bottom: 110px;
    text-align: center;
`;

const Image = styled.img`
    width: 553px;
    @media only screen and (max-width: 1050px) {
        width: 358px;
    }
    @media only screen and (max-width: 1050px) {
        margin-bottom: 40px;
    }
`;


const Spacer = styled.div`
    width: 80px;
`;

const Header = styled(_Header)`
    letter-spacing: -0.65px;
    line-height: 1;
    max-width: 473px;
    margin: inherit;
    margin-bottom: 20px;
`;

const Paragraph = styled(_Paragraph)`
    max-width: 480px;
    margin-bottom: 20px;
`;

const Link = styled.span`
    color: ${props => props.theme.color.lightBlue};
    text-decoration: none;
    font-family: ${fonts.gotham};
    font-size: 18px;
    font-weight: 500;
    &:hover {
        cursor: pointer;
        color: ${props => props.theme.color.primaryDarker1};
    }
`;
