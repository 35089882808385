import React, { FC, memo, useContext } from 'react';
import { Link as RouterLink } from "react-router-dom";
import styled, { ThemeContext, css } from "styled-components";
import Config from '../../config';
import { fonts } from "../../styles";

interface Props {
    className?: string;
    onMenuPress: VoidFunction;
}

const Menu: FC<Props> = ({ className, onMenuPress }) => {
    const theme = useContext(ThemeContext);

    return(
        <Wrapper className={className}>
            <RouterLink to="/">
                <Logo src={theme.img.logo} />
            </RouterLink>
            <DesktopNav>
                <Link to="/">Home</Link>
                <Link to="/about">About</Link>
                <Link to="/news">News</Link>
                <Link to="/pricing">Pricing</Link>
                {/* <Link to="/contact">Contact</Link> */}
                {/* <Link to="/demo">Demo</Link> */}
                <LinkButton href={"https://" + Config.dashboardSubdomain + "." + theme.domain}>Login</LinkButton>
            </DesktopNav>
            <MenuIcon onClick={onMenuPress} />
        </Wrapper>
    )
}

export default memo(Menu);

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 20px;
    padding-bottom: 20px;
`;

const Logo = styled.img`
    width: 65px;
    height: 50px;
`;

const DesktopNav = styled.nav`
    @media only screen and (max-width: 750px) {
        display: none;
    }
`;

const MenuIcon = styled.div`
    
    width: 22px;
    height: 19px;
    background-image: url( ${props => props.theme.img.menuIcon});
    background-repeat: no-repeat;
    background-position: center center;
    &:hover {
        cursor: pointer;
    }
    @media only screen and (min-width: 750px) {
        display: none;
    }
`;

const LinkCopyCss = css`
    font-size: 16px;
    font-family: ${fonts.gotham};
    font-weight: bold;
    text-decoration: none;
    color: ${props => props.theme.color.darkGray};
    padding: 14px 22px;
    margin-right: 12px;
    margin-left: 12px;
    padding-top: 14px;
`;

const Link = styled(RouterLink)`
    ${LinkCopyCss}
    &:hover {
        color:  ${props => props.theme.color.primary};
    }
`;

const LinkButton = styled.a`
    ${LinkCopyCss}
    display: inline-block;
    background-color: ${props => props.theme.color.primary};
`;