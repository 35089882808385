import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { Header, Paragraph as _Paragraph } from "../../components/atoms";
import { Content, Brand } from "../../styles/theme"

export const Privacy = () => {
    const brand: Brand = useContext(ThemeContext).brand;
    
    return(
        <Wrapper>
            <Header>
                privacy policy
        </Header>

            <Title>FACECHECK LIMITED</Title>
            <Paragraph></Paragraph>

            <Paragraph>At Facecheck Limited, (&ldquo;Facecheck&rdquo;, &ldquo;we&rdquo;, &ldquo;us&rdquo; or &ldquo;our&rdquo; in this policy) we are committed to respecting your privacy and protecting the personal information that we obtain and hold about you and others.</Paragraph>
            <Paragraph></Paragraph>
            <Paragraph>This Website Privacy Policy (&ldquo;Policy&rdquo;) explains how we will process the data relating to you that you provide us when you visit on of our websites at <a href="https://www.facecheck.ai/" target="_blank2" rel="noopener" data-content="https://www.facecheck.ai/" data-type="external">https://www.facecheck.ai/</a> or <a href={`https://www.${content[brand].domain}.ai`} target="_blank2" rel="noopener" data-content={`https://www.${content[brand].domain}.ai`} data-type="external">{`https://www.${content[brand].domain}.ai`}</a> (&ldquo;Website&rdquo;), or that we may collect from others about you when you visit our Website, or use our Facecheck Limited mobile application, the {content[brand].brandName} app (&ldquo;{content[brand].brandName} App&rdquo;) or in relation to any query or communication that you may send to us.</Paragraph>
            <Paragraph></Paragraph>


            <Title>1. Introduction and General Terms</Title>
            <Paragraph>The Website is operated by Facecheck Limited, a company registered under company number 12468906 with registered address at 2nd Floor, 130 Shaftesbury Avenue, London W1D 5EU, United Kingdom. The Website allows individuals to view our services and understand how our technology is deployed, allows individuals to connect with us and also allows our clients to access their data which supports the functionality of the {content[brand].brandName} App.&nbsp;(&ldquo;Services&rdquo;).</Paragraph>
            <Paragraph>Where we decide the purpose or means for the processing of the personal data that you provide when using our Website, we are the &ldquo;data controller&rdquo; for the purposes of the General Data Protection Regulation 2016 (&ldquo;GDPR&rdquo;) and the UK Data Protection Act 2018.</Paragraph>
            <Paragraph></Paragraph>
            <Paragraph>Where we provide ancillary services to our clients using the {content[brand].brandName} App, described in this privacy policy, we act as &rsquo;data processor' acting on behalf of our clients and their instructions. Once we provide our {content[brand].brandName} App to a client, the client then determines how they will process information and decide the purposes the information will be used for (including the appropriate lawful basis). As data processor, we will comply with all applicable data protection laws.</Paragraph>
            <Paragraph></Paragraph>
            <Paragraph>Our Website may contain hyperlinks to third party websites. These websites operate fully independently from us, and we cannot accept any responsibility or liability for the privacy practices of such third parties nor the availability of these external sites or resources. The appearance of such links on our Website is not an endorsement. Should you use any of these websites, such use is at your own risk and we would recommend that you review their respective privacy policies.</Paragraph>
            <Paragraph></Paragraph>


            <Title>2. What Information Will We Collect About You?</Title>
            <Subtitle>Information provided by you when using our Services</Subtitle>
            <Paragraph>You may give us information about you by filling in forms for our clients for whom we are providing our Services, registering and using the {content[brand].brandName} App or contacting us, when acting as a service provider for clients to facilitate and answer enquiries on their behalf. Such information can include:</Paragraph>
            <Paragraph>
            <ul>
                <li>
                    <Paragraph>Identity and contact data: including your name, email address, date of birth, telephone number when registering and using our Website or the {content[brand].brandName} App.</Paragraph>
                </li>
                <li>
                    <Paragraph>Correspondence: including any information you submit to us in messages or uploaded content via the Website or {content[brand].brandName} App.</Paragraph>
                </li>
                <li>
                    <Paragraph>Images or other content relating to you: including your photo, identification documents (for example passport or driving licence number) and any other information you provide or uploaded in order to validate your identity and facilitate facial recognition on the {content[brand].brandName} App.</Paragraph>
                </li>
                <li>
                    <Paragraph>Technical information on the {content[brand].brandName} App: including location and timestamp data from your device which tracks your location using GPS technology and your IP address.</Paragraph>
                </li>
            </ul>
            </Paragraph>
            <Paragraph>The foregoing shall be referred to herein as (&ldquo;Personal Data&rdquo;)</Paragraph>


            <Title>3. How We Use Your Personal Data?</Title>
            <Paragraph></Paragraph>
            <Subtitle>Providing the Services to you and our partners</Subtitle>
            <Paragraph>We may legally process and retain your Personal Data where we need to perform the contract we are about to enter into or have entered into with our clients so as to provide the services to them via the means of providing the {content[brand].brandName} App.<br /><br />We process that information to provide ancillary Services to our clients on their behalf when using the {content[brand].brandName} App in pursuit of our or their legitimate interests, including, without limitation, in maintaining content on our Website and promoting our Services.</Paragraph>
            <Paragraph>If you would like to find out more information about how our partners use and process your information, please refer to their respective polices.</Paragraph>
            <Subtitle>Contacting Us (Your Enquiries)</Subtitle>
            <Paragraph>If you contact us via our Website about our Services, to make an enquiry or otherwise, the webpage asks you to provide a limited amount of personal information about yourself, such as your name, email address, subject (e.g. purpose of your message) and your message or enquiry. We will use this information to respond to your enquiries, for example:&nbsp;</Paragraph>
            <Paragraph>In line with the legitimate interest we have in promoting our business, we will process your enquiries to reply to your query, including providing you with information about the Services we offer.</Paragraph>
            <Paragraph>We may also process enquiries to take steps you ask of us with a view to entering into an agreement to provide you with our Services. You are under no obligation to provide us with any details, but if you choose to not provide us with relevant information, we may not be able to respond and/or provide the assistance that you request.</Paragraph>
            <Subtitle>Mailing List</Subtitle>
            <Paragraph>If you ask to be added to our mailing list, we will keep you updated with information on news and updates about our Services via email.</Paragraph>
            <Paragraph>In line with the legitimate interest we have in promoting our business, we will use the email address you gave us via our Website to provide you with this information. If you would like to be removed from that list please let us know by clicking unsubscribe at the bottom of any marketing email that you receive.</Paragraph>
            <Paragraph>There may also be circumstances where we will ask you for your prior consent in respect of our use of your personal data or where we may rely on an alternative lawful basis to process your personal data in which case you will be notified at the time.</Paragraph>
            <Subtitle>How You Use our Website (Analytics &amp; Cookies)</Subtitle>
            <Paragraph>Our Website uses cookies and other mechanisms to collect and log analytical information, to help analyse how visitors use the Website and to compile statistical reports on Website activity. This processing is crucial to the running of our online business. When you visit our Website, our analytics service providers may collect the following data:</Paragraph>
            <Paragraph>
            <ul>
                <li>
                    <Paragraph>number of visitors to our website;</Paragraph>
                </li>
                <li>
                    <Paragraph>pages visited while at the website and time spent per page;</Paragraph>
                </li>
                <li>
                    <Paragraph>page interaction information, such as scrolling, clicks and browsing methods;</Paragraph>
                </li>
                <li>
                    <Paragraph>websites where visitors have come from and where they go afterwards;</Paragraph>
                </li>
                <li>
                    <Paragraph>page response times and any download errors; and</Paragraph>
                </li>
                <li>
                    <Paragraph>other technical information relating to end user device, such as IP address or browser plugin</Paragraph>
                </li>
            </ul>
            </Paragraph>
            <Paragraph>Should you visit social media pages and choose to follow or connect with us on social media, we may receive personal information from the social media providers (depending on your social media privacy settings), including:</Paragraph>
            <Paragraph>
                <ul>
                    <li>
                        <Paragraph>your name (and/or your social media unique user name);</Paragraph>
                    </li>
                    <li>
                        <Paragraph>your email and/or postal address;</Paragraph>
                    </li>
                    <li>
                        <Paragraph>your telephone number(s);</Paragraph>
                    </li>
                    <li>
                        <Paragraph>IP or MAC address;</Paragraph>
                    </li>
                    <li>
                        <Paragraph>your gender and/or age; and</Paragraph>
                    </li>
                    <li>
                        <Paragraph>your general interests and preferences (e.g. Likes, Shares, Posts)</Paragraph>
                    </li>
                </ul>
            </Paragraph>
            <Paragraph>Most web browsers allow some control of most cookies through the browser settings.</Paragraph>
            <Paragraph>All content you submit to us</Paragraph>
            <Paragraph>If you send us objectionable content or otherwise behave in a disruptive manner when using our Website, we may process personal data included in your messages to respond to and stop such behaviour.</Paragraph>
            
            <Paragraph>
            <ul>
                <li>
                    <Paragraph>We only process personal data in this way for the legitimate interests of ensuring that use of our Website is lawful, does not disrupt our systems, does not harass our staff or other individuals, and to enforce our legal rights and to comply with our legal obligations.</Paragraph>
                </li>
                <li>
                    <Paragraph>Where we reasonably believe you are or may be in breach of the law (for instance, because content you send amounts to harassment or is defamatory), we may use your personal information to inform relevant third parties such as your email/internet provider or law enforcement agencies about the content.</Paragraph>
                </li>
            </ul>
            </Paragraph>
            <Paragraph>Where we process personal data in this way, we will hold that personal information on our systems for as long as is reasonably necessary to achieve these objectives.</Paragraph>
            

            <Title>4. Do We Share Your Personal Data with Anyone Else?</Title>
            <Paragraph></Paragraph>
            <Paragraph>We keep your information confidential, but may disclose it to our personnel, suppliers or subcontractors and our clients insofar as it is reasonably necessary for the purposes set out in this privacy policy, including for the performance of contracts and in line with our legitimate interests in regard to conducting our business. If we provide your personal information to third parties, we shall only do so provided that they do not make independent use of the information and provide the same confidential treatment as we do.&nbsp;</Paragraph>
            <Paragraph>We only permit them to process your personal data for specified purposes and in accordance with our instructions. All our third party service providers are required to take appropriate security measures to protect your personal information in line with our policies. Activities which are carried out by or in collaboration with third party service providers include website hosting and our fulfilment service provider.</Paragraph>
            <Paragraph></Paragraph>
            <Paragraph>In addition, we may disclose your information to the extent that we are required to do so by law (which may include to government bodies and law enforcement agencies); in connection with any legal proceedings or prospective legal proceedings; and to establish, exercise or defend our legal rights (including providing information to others for the purposes of fraud prevention).</Paragraph>
            <Paragraph></Paragraph>
            <Paragraph>If we are involved in a merger, acquisition, or sale of all or a portion of our business or assets, the information we hold may be included as part of that sale, in which case you will be notified via email and/or a prominent notice on the Website of any changes in ownership or use of your information, as well as any choices you may have regarding that information.</Paragraph>
            <Paragraph></Paragraph>
            <Paragraph>Except as provided above, we will not provide your information to third parties.</Paragraph>
            <Paragraph></Paragraph>
            <Paragraph>5. Your rights in relation to your personal data which we process</Paragraph>
            <Paragraph></Paragraph>
            <Paragraph>You have the following rights over the way we process personal data relating to you. We aim to comply without undue delay, and within one month at the latest, in response to any requests by you:</Paragraph>
            <Paragraph>
            <ul>
                <li>
                    <Paragraph>for a copy of data we are processing about you and/or to have inaccuracies corrected;</Paragraph>
                </li>
                <li>
                    <Paragraph>to restrict, stop processing, or to delete your personal data;</Paragraph>
                </li>
                <li>
                    <Paragraph>for a machine readable copy of your personal data, which you can use with another service provider. Where it is technically feasible, you can ask us to send this information directly to another provider if you prefer; and</Paragraph>
                </li>
                <li>
                    <Paragraph>to make a complaint to a data protection regulator. You may contact them at: <a href="https://ico.org.uk/concerns/" target="_blank2" rel="noopener" data-content="https://ico.org.uk/concerns/" data-type="external">https://ico.org.uk/concerns/</a></Paragraph>
                </li>
            </ul>
            </Paragraph>
            <Paragraph>To make a request in relation to any of the aforementioned rights, please send your requests to <object width="300" height="0"><a data-auto-recognition="true" data-content="contact@facecheck.ai" href="mailto:contact@facecheck.ai" data-type="mail">contact@facecheck.ai</a></object> or write to us at 2nd Floor, 130 Shaftesbury Avenue, London W1D 5EU, United Kingdom.</Paragraph>
            <Paragraph></Paragraph>


            <Title>6. Children</Title>
            <Paragraph></Paragraph>
            <Paragraph>We do not use the Website to knowingly solicit data from or market to children under the age of 13. If a parent or guardian becomes aware that his or her child has provided us with information without their consent, he or she should contact us at contact@facecheck.ai. We will delete such information within a reasonable time.</Paragraph>
            

            <Title>7. Security</Title>
            <Paragraph></Paragraph>
            <Paragraph>We will take commercially reasonable, appropriate technical and organisational measures to ensure a level of security appropriate to the risk that could be encountered via the use of our Website and services, taking into account the likelihood and severity those risks might pose to the rights and freedoms of our Website visitors.</Paragraph>
            <Paragraph></Paragraph>
            <Paragraph>In particular, we will take precautions to protect against the accidental or unlawful destruction, loss or alteration, and unauthorised disclosure of or access to the personal information transmitted, stored or otherwise processed by us.</Paragraph>
            <Paragraph>Please be aware that, while we make the security of our Website and your personal information a high priority and devote considerable time and resources to maintain robust IT security, no security system can prevent all security breaches. When you choose to share your personal information with us, you accept the aforesaid and provide your information at your own risk. </Paragraph>
            

            <Title>8. International Data Transfers</Title>
            <Paragraph></Paragraph>
            <Paragraph>Our servers are located in the EEA and the information that we collect directly from you will be stored in these servers. We may also transfer your personal data to members of our third party service providers, many of whom may be located outside of the EU, operate from multiple locations including non-EU based operations or engage sub-processors located outside the EU.</Paragraph>
            <Paragraph>There are agreements in place with all of our third party service providers, worldwide to ensure that those parties process personal data using appropriate safeguards that meet the requirements of data protection laws. Such appropriate safeguards may include standard data protection clauses adopted by a data protection regulator and approved by the European Commission, such as the European Commission&rsquo;s standard contractual clauses.</Paragraph>
            <Paragraph>If you would like to find out more about these safeguards or if you have any other queries or comments in relation to this policy, please let us know by emailing us at contact@facecheck.ai.</Paragraph>
            <Paragraph>To find out more about the standard contractual clauses, please visit: <a href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/model-contracts-transfer-personal-data-third-countries_en" target="_blank2" rel="noopener" data-content="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/model-contracts-transfer-personal-data-third-countries_en" data-type="external">https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/model-contracts-transfer-personal-data-third-countries_en</a></Paragraph>
            
            
            <Title>9.&nbsp;Retention Periods or Criteria</Title>
            <Paragraph></Paragraph>
            <Paragraph>We will hold your Personal Data for as long as is necessary for the relevant purpose for which that Personal Data was obtained, or as otherwise described in this Policy.</Paragraph>
            <Paragraph>To determine the appropriate retention period for Personal Data, we consider the amount, nature, and sensitivity of the Personal Data, the potential risk of harm from unauthorized use or disclosure of your Personal Data, the purposes for which we process your Personal Data and whether we can achieve those purposes through other means, and the applicable legal requirements.If you would like us to stop using or holding your personal information and/or would like to request its deletion, please email us at contact@facecheck.ai.</Paragraph>
            
            
            <Title>10. Changes</Title>
            <Paragraph></Paragraph>
            <Paragraph>This Policy may be updated from time to time. We will notify you of any changes by posting the new policy here and, where feasible, by letting you know by email.</Paragraph>
            
            
            <Title>11. Contact Us</Title>
            <Paragraph>Questions, comments and requests in relation to this Policy are welcome and should be addressed to contact@facecheck.ai.</Paragraph>
            <Paragraph>Last Updated: May 2020</Paragraph>


        </Wrapper>
    )
}

export default Privacy;

const content: Content = {
    work: {
        brandName: "Fit for Work",
        domain: "fitforwork"
    },
    sport: {
        brandName: "Fit for Sport",
        domain: "fitforsport"
    },
    travel: {
        brandName: "Fit for Travel",
        domain: "firfortravel"
    }
}

const Wrapper = styled.div`
    padding-top: 100px;
    padding-bottom: 100px;
    text-align: center;
`;

const Paragraph = styled(_Paragraph)`
    max-width: 900px;
    font-size: 14px;
    margin: auto;
    margin-bottom: 8px;
    margin-top: 8px;
    text-align: left;
`;

const Title = styled(Paragraph)`
    font-weight: bold;
    margin-top: 20px;
`;

const Subtitle = styled(Title)`
    font-weight: normal;
    text-decoration: underline;
`;