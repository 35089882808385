import React, { useEffect } from "react";
import { BrowserRouter, Switch, Route, useLocation } from "react-router-dom";
import Home from "./pages/home";
import About from "./pages/about";
import News from "./pages/news";
import Pricing from "./pages/pricing";
import Contact from "./pages/contact";
import Download from "./pages/download";
import DownloadSport from "./pages/download-sport";
import Privacy from "./pages/privacy";
import Layout from "./pages/_layout";
import ReactGA from "react-ga";
import config from "./config";
import './App.css';

const App = () => {
    return (
        <BrowserRouter>
            <Layout>
                <AppWithPageViews />
            </Layout>
        </BrowserRouter>
    );
}

const AppWithPageViews = () => {
    const location = useLocation();
    useEffect(() => {
        if (config.googleAnalyticsId) {
            ReactGA.initialize(config.googleAnalyticsId);
        }
    }, [])

    useEffect(() => ReactGA.pageview(location?.pathname), [location]);

    return (
        <Switch>
            <Route path={["/about"]} component={About} />
            <Route path={["/news"]} component={News} />
            <Route path={["/pricing"]} component={Pricing}/>
            <Route path={["/contact"]} component={Contact} />
            <Route exact path={["/download"]} component={Download} />
            <Route exact path="/fitforsport/download" component={DownloadSport}/>
            <Route path={["/privacy"]} component={Privacy} />
            <Route path={["/"]} component={Home} />
        </Switch>
    )
}

export default App;
