import React, { FC, memo, useContext } from 'react';
import styled, { ThemeContext }from "styled-components";
import { fonts } from "../../styles";
import { Header, Button as _Button } from "../../components/atoms";
import { Content, Brand } from "../../styles/theme"

interface Props {
    onPlayClick: VoidFunction;
}

const Hero: FC<Props> = ({ onPlayClick }) => {
    const brand: Brand = useContext(ThemeContext).brand;

    return(
        <Wrapper>
            <Header>
                {content[brand].header}
            </Header>
            <Subheader>
                {content[brand].subheader}
            </Subheader>
            <Button href={content[brand].demoUrl}>
                Book a demo
            </Button>
            {(brand==='work') ? <PlayButton onClick={onPlayClick} /> : ''}
        </Wrapper>
    )
}

export default memo(Hero);

const content: Content = {
    work: {
        header: 'Keep your workplace safe.',
        subheader: 'The app that empowers workers to keep each other safe, for total peace-of-mind.',
        demoUrl: 'https://facecheckcontact.typeform.com/to/QO7QgV0x'
    },
    sport: {
        header: 'Keep your sport safe.',
        subheader: 'The app that empowers sports people to keep each other safe, for total peace-of-mind.',
        demoUrl: 'https://facecheckcontact.typeform.com/to/w2eMr6kx'
    },
    travel: {
        header: 'Keep your travel safe.',
        subheader: 'The app that empowers passengers to keep each other safe, for total peace-of-mind.',
        demoUrl: 'https://facecheckcontact.typeform.com/to/V3HYsPtP'
    }
}

const Wrapper = styled.div`
    padding-top: 20px;
    text-align: center;
    min-height: 500px;
    background-image: url(${props => props.theme.img.heroBg});
    background-repeat: ${props => props.theme.css.heroRepeat};
    background-position: center bottom;

    @media only screen and ( -webkit-min-device-pixel-ratio: 1.3 ),
    only screen and (    min--moz-device-pixel-ratio: 1.3 ),
    only screen and (      -o-min-device-pixel-ratio: 2.6/2 ), /* returns 1.3, see Dev.Opera */
    only screen and (         min-device-pixel-ratio: 1.3 ),
    only screen and ( min-resolution: 124.8dpi ),
    only screen and ( min-resolution: 1.3dppx ) {
        ${ props => props.theme.img.heroBg2x && props.theme.img.heroBg2x };
        ${ props => props.theme.css.heroPos && props.theme.css.heroPos };
        ${ props => props.theme.css.heroSize && props.theme.css.heroSize };
    }

    @media only screen and (max-width: 750px) {
        padding-left: 40px;
        padding-right: 40px;
        ${ props => props.theme.css.heroPosBP1 && props.theme.css.heroPosBP1};
        ${ props => props.theme.css.heroSizeBP1 && props.theme.css.heroSizeBP1 };
    };
`;

const Subheader = styled.h2`
    font-family: ${fonts.gothamBook};
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    color: ${props => props.theme.color.darkGray};
    max-width: 600px;
    margin: auto;
    padding-top: 20px;
    @media only screen and (max-width: 750px) {
        font-size: 16px;
        line-height: 26px;
    }
`;

// TODO: move to atom
const Button = styled(_Button)`
    margin-top: 30px;
`;

const PlayButton = styled.div`
    width: 62px;
    height: 62px;
    background-image: url(${props => props.theme.img.playIcon});
    background-size: cover;
    background-repeat: no-repeat;
    margin: auto;
    margin-top: 20px;
    opacity: 0.9;
    &:hover {
        cursor: pointer;
        opacity: 1;
    }
`;