import React, { useEffect } from "react";
import styled from "styled-components";
import { Header } from "../../components/atoms";

export const News = () => {

  useEffect(() => (window as any).startMediumWidget())
  
  return  <Wrapper>
            <Header>The latest from our blog</Header>
            <MediumWidget id="medium-widget"/>
          </Wrapper>
}

export default News;

const Wrapper = styled.div`
  text-align: center;
  margin-top: 20px
`

const MediumWidget = styled.div`

  margin: 50px auto;
  max-width: 730px;

  @media (max-width: 750px) {
    margin: 10px 20px;
  }
`