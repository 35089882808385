import React, { FC, memo, useContext } from 'react';
import styled, { ThemeContext, css } from "styled-components";
import Config from '../../config';
import { fonts } from "../../styles";
import { DismissableOverlay } from "../../components/organisms";

interface Props {
    onClose: VoidFunction;
}

const MenuFullScreen: FC<Props> = ({ onClose }) => {

    const theme = useContext(ThemeContext);

    return (
        <DismissableOverlay onClose={onClose}>
            <Link href="/">Home</Link>
            <Link href="/about">About</Link>
            <Link href="/news">News</Link>
            <Link href="/pricing">Pricing</Link>
            {/* <Link to="/contact">Contact</Link> */}
            {/* <Link to="/demo">Demo</Link> */}
            <LinkButton href={"https://" + Config.dashboardSubdomain + "." + theme.domain}>Login</LinkButton>
        </DismissableOverlay>
    )
}

export default memo(MenuFullScreen);

const linkCss = css`
    color: ${props => props.theme.color.darkGray};
    font-family: ${fonts.gotham};
    text-decoration: none;
    font-weight: bold;
    display: block;
    font-size: 40px;
    padding: 20px 50px;
    margin: 15px;
`;

const Link = styled.a`
    ${linkCss}
`;

const LinkButton = styled.a`
    ${linkCss}
    background-color: ${props => props.theme.color.darkGray};
    color: ${props => props.theme.color.primary};
`;