import React, { useContext } from "react";
import styled, {ThemeContext} from "styled-components";
import { Header as _Header, Paragraph as _Paragraph } from "../../components/atoms";

const loremIpsum = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."

const Mission = () => {

    const theme = useContext(ThemeContext);

    return <Wrapper>
        <Header size={30}>Our Mission</Header>
        <SubHeader>Digital PPE in the hands of every remote worker</SubHeader>
        {/* <CardWrapper> */}
            <Card>
                <Image src={theme.img.missionSafety}/>
                <Header size={30}>Safety</Header>
                <Paragraph>{loremIpsum}</Paragraph>
            </Card>
            <Card>
                <Image src={theme.img.missionHealth}/>
                <Header size={30}>Health</Header>
                <Paragraph>{loremIpsum}</Paragraph>
            </Card>
            <Card>
                <Image src={theme.img.missionSupport}/>
                <Header size={30}>Support</Header>
                <Paragraph>{loremIpsum}</Paragraph>
            </Card>
        {/* </CardWrapper> */}
    </Wrapper>
}

export default Mission

const Wrapper = styled.div`
`

const Header = styled(_Header)`
    margin: 40px auto 0px auto
`

const SubHeader = styled(_Paragraph)`
    text-align: center;
    margin: 20px auto;
    max-width: 2000px;

    @media only screen and (max-width: 750px) {
        margin: 20px;
    }
`

const Card = styled.div`
    margin: 80px 60px;
    display: inline-block;
    max-width: 250px;

    @media only screen and (max-width: 750px) {
        margin: 20px auto;
        max-width: 600px;
    }
`

const Image = styled.img`
    object-fit: contain;
    height: 200px;
    width: 200px;
`

const Paragraph = styled(_Paragraph)`
    margin: 40px auto;

    @media only screen and (max-width: 750px) {
        margin: 20px;
    }
`